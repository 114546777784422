"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_step_progress_bar_1 = require("react-step-progress-bar");
class MultiStepProgressBar extends react_1.Component {
    constructor() {
        super(...arguments);
        this.getProgressPercent = () => {
            const { page, steps } = this.props;
            const stepCount = steps.length;
            if (page === 1) {
                return 0;
            }
            else {
                return Math.ceil(100 * (page - 1) / (stepCount - 1));
            }
        };
        this.onClick = (event) => {
            let clickedIcon = event.currentTarget.getAttribute('data-page-num');
            if (typeof clickedIcon === 'string') {
                clickedIcon = parseInt(clickedIcon, 10);
            }
            this.props.onIconClick(clickedIcon);
        };
        this.renderSteps = () => {
            const { steps } = this.props;
            return steps.map((step, index) => {
                return (react_1.default.createElement(react_step_progress_bar_1.Step, { key: index, transition: "scale" }, (stepProps) => {
                    const disabled = step.disabled ? 'disabled' : '';
                    return (react_1.default.createElement(react_1.default.Fragment, null,
                        react_1.default.createElement("span", { className: `fa-stack fa-lg step-icon ${disabled}`, "data-page-num": step.page, onClick: this.onClick },
                            react_1.default.createElement("i", { className: `fa fa-circle fa-stack-2x ${stepProps.accomplished && 'complete'}` }),
                            react_1.default.createElement("i", { className: `fa ${step.iconClass} fa-stack-1x fa-inverse` })),
                        react_1.default.createElement("div", { className: "icon-label" }, step.iconLabel)));
                }));
            });
        };
    }
    render() {
        return (react_1.default.createElement("div", { className: "enrollment-steps-bar-container" },
            react_1.default.createElement(react_step_progress_bar_1.ProgressBar, { percent: this.getProgressPercent(), filledBackground: "#43bb85" }, this.renderSteps())));
    }
}
exports.default = MultiStepProgressBar;

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const reactstrap_1 = require("reactstrap");
const ui_comment_widget_1 = require("ui-comment-widget");
const apifw_1 = require("../../requests/apifw");
class CommentModal extends react_1.Component {
    render() {
        return (react_1.default.createElement("div", null,
            react_1.default.createElement(reactstrap_1.Modal, { className: "comment-modal", isOpen: this.props.commentWidgetOpen },
                react_1.default.createElement(reactstrap_1.ModalHeader, { toggle: this.props.toggleCommentWidget }, "Comments"),
                react_1.default.createElement(reactstrap_1.ModalBody, null,
                    react_1.default.createElement(ui_comment_widget_1.CommentWidget, { currentUserId: this.props.currentUserId, updateSelectedComment: this.props.updateSelectedComment, commentData: this.props.commentData, getComments: this.props.getComments, getUsername: apifw_1.getUserById, onSaveComment: this.props.onSaveComment, loading: this.props.loading, moderatorEditComments: true, moderatorDeleteComments: true, 
                        // canDeleteComments={true}
                        // deleteComment={this.props.deleteComment}
                        // moderatorEditComments={PermissionsVerify(
                        //     userPermissions,
                        //     'workit:admin:comment_moderator',
                        //     true,
                        //     false)}
                        // moderatorDeleteComments={PermissionsVerify(
                        //     userPermissions,
                        //     'workit:admin:comment_moderator',
                        //     true,
                        //     false)}
                        canEditComments: true })),
                react_1.default.createElement(reactstrap_1.ModalFooter, null,
                    react_1.default.createElement(reactstrap_1.Button, { onClick: this.props.toggleCommentWidget }, "Close")))));
    }
}
exports.default = CommentModal;
